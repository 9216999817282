








































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Banner extends Vue {
  dialog = false;
}
