






























import { Component, Vue } from "vue-property-decorator";

@Component
export default class Faq extends Vue {
  items = [
    {
      question: "How to use Diji Vatandas Online Education Platform?",
      answer: `<iframe src="https://player.vimeo.com/video/557564964" width="420" height="236" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`
    },
    {
      question: "What is Digital Citizenship ID?",
      answer: `<p>The Digital Citizenship Identity is a document that, upon successful completion of all modules in the Diji Citizen Education Program, indicates that the participant is digitally literate in a way to capture the global discussion on the fundamental issues of current digital technologies, and can be considered an indicator of an individual who can easily adapt to digital transformation processes.</p>
      <p>Further information will be provided as agreements are reached with institutions and organizations that will be valid within the scope of the program. Digital Citizenship Identity in national and international media; it does not have any legal nature, questionability and validity at the judicial, administrative and bureaucratic level. It is a certificate of competence, it does not provide any legal privileges.</p>`
    },
    {
      question: "Are the courses face-to-face?",
      answer:
        "<p>No. Courses in the modules are in interactive e-lesson format. You can learn from anywhere, anytime, with interactive e-lessons enriched with video, sound, animation and interaction elements.</p>"
    },
    {
      question: "Can I obtain a certificate after completion of courses?",
      answer: `<p>Yes, participants obtain a certification of participation for successful completion of each course.</p>`
    },
    {
      question: "What is a Certificate of Participation?",
      answer: `<p>The certificate of participation is a document verified by the Diji Vatandas Online Education Platform and given after all interactive e-lessons of the course successfully completed.</p>`
    },
    {
      question:
        "Is it possible to access and complete modules via mobile devices?",
      answer: `<p>Yes. Since the e-learning infrastructure of Diji Vatandas is designed independently of the device and platform, you can access it from any mobile device and operating system.</p>`
    }
  ];
}
