















































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Team extends Vue {
  tab = null;
  dialog = false;

  selectedMember = {
    img: "",
    title: "",
    fullName: "",
    bio: ""
  };

  memberList = [
    {
      img: require("@/assets/cansu.png"),
      title: "Proje Coordinator",
      fullName: "Cansu Cosan",
      bio: `<p>Cansu Coşan, İstanbul Üniversitesi İletişim Fakültesi Gazetecilik Ana dal ve Radyo Sinema Televizyon Çift Ana dal Bölümleri'nden 2012 yılında mezun  olmuştur. Aynı yıllar içerisinde Pedagojik Formasyon alarak Medya  Okuryazarlığı alanında öğretmenlik sertifikası almaya hak kazanmıştır. Cansu  COŞAN, İstanbul Üniversitesi İletişim Fakültesi Gazetecilik Ana dal ve  Radyo Sinema Televizyon Çift Ana dal Bölümleri'nden 2012 yılında mezun  olmuştur. Aynı yıllar içerisinde Pedagojik Formasyon alarak Medya  Okuryazarlığı alanında öğretmenlik sertifikası almaya hak kazanmıştır. COŞAN, 2011 yılında TÜBİTAK Genç Araştırmacılarından biri olarak  seçilerek; Romanya, İtalya, Yunanistan, İngiltere ve Rusya'da akademik  anlamda gerçekleştirdiği araştırmalarını sunmuştur. Ardından Marmara Üniversitesi Sosyal Bilimlerde İletişim Bilimleri alanında yüksek  lisansı tamamlamıştır. Bir çok araştırma, kongre ve kurumlara eğitim içerikleri geliştirme projelerinin başında yer almıştır. COŞAN özellikle Girişimcilik  ve Medya Okuryazarlığı üzerine eğitimler vererek, dijital içerik ve program  geliştirme üzerine çalışmalarına devam etmekte ve şu anda Haliç Üniversitesi'nde Teknoloji Transfer Ofisi'nde Proje ve Eğitim Koordinatörü olarak çalışmaktadır.</p>`
    },
    {
      img: require("@/assets/sonia.png"),
      title: "Proje Coordinator",
      fullName: "Su Sonia Herring",
      bio: `<p>Su Sonia Herring, lisansını Hacettepe Üniversitesi Dilbilimi bölümünde, yüksek lisansını ise Centre international de formation européenne’de Avrupa Birliği ve Uluslararası Çalışmalar üzerine tamamladı. 2015’ten bu yana küresel internet yönetişimi ekosisteminde aktif rol alan Su Sonia; Türkiye, Almanya, Afganistan, Romanya, Azerbaycan, Kenya ve İtalya’daki kapasite oluşturma projelerinde editör, eğitmen ve danışman olarak yer aldı. Birleşmiş Milletler tarafından tanınan Youth IGF Turkey forumunun 5 yıldır koordinatörlüğünü yürüten Su Sonia, 22 ülkeyi kapsayan Güneydoğu Avrupa İnternet Yönetişimi Diyaloğu (SEEDIG) ve İnternet Derneği’nde (ISOC-TR) yönetim kurulu üyesi olarak görev yapıyor.</p>
      <p>Dijital Vatandaşlık online eğitim platformunun proje uzmanlarından olan Su Sonia, aynı zamanda İnternet Yönetişimi ve Ekosistemi modülünün editörü.</p>`
    },
    {
      img: require("@/assets/idil.png"),
      title: "Proje Coordinator",
      fullName: "Idil Kula",
      bio: `<p>Yasal avukatlık stajını sürdürmekte olan İdil, ODTÜ Enformatik Enstitüsü Yazılım Yönetimi bölümünde Yüksek Lisans öğrencisidir. Internet Yönetişimde Gençlik Diyalog'unda(YOUthDIG) aktif olarak görev almakta, EuroDIG 2021 sürecine katkıda bulunmaktadır. Araştırmalarını teknoloji, veri bilimi, insan hakları ve etik gibi konuların ekseninde gerçekleştiren İdil, 2021 yılında Harvard Berkman Klein Center for Internet and Society ve Digital Asia Hub tarafından Dijital Self-Determinasyon üzerinde gerçekleştirilen çalışmalarda araştırmacı olarak bulunmuştur. Ayrıca İstanbul Bilgi Üniversitesi bünyesinde yürütülen Akıllı Şehir Uygulamalarının  Etik Etkileri projesinde de proje asistanı olarak çalışmaktadır.</p>`
    },
    {
      img: require("@/assets/serkan.png"),
      title: "Instructor",
      fullName: "Serkan Yesilyurt",
      bio: `<p>1983 yılında Ordu’da doğdu. İlk, orta ve lise öğrenimini Ordu’da tamamladı. Lisans öğrenimini 2003-2008 yılları arasında Bahçeşehir Üniversitesi İktisadi, İdari ve Sosyal Bilimler Fakültesi Ekonomi / Siyaset Bilimi ve Uluslararası İlişkiler Bölümlerinden aldı. Mesleki kariyerine 2008 yılında Bahçeşehir Üniversitesinde araştırma görevlisi olarak başladı.</p>
      <p>Sermaye Piyasaları ve Finans Alanında 2011 yılında yüksek lisans ve 2018 yılında Finans alanında doktorasını tamamladı ve Bahçeşehir Üniversitesi İktisadi, İdari ve Sosyal Bilimler Fakültesi Ekonomi Bölümü’ne öğretim üyesi olarak atandı. Aynı Fakültede 2013 yılında Finansal Araştırma ve Uygulama Merkezini kurdu ve Merkez koordinatörlüğü görevini yürütmektedir.</p>
      <p>Serkan Yeşilyurt, üniversitelerde ve sivil toplum kuruluşlarında İstatistik, Ekonometri, Finansal Analiz, Portföy Yönetimi, Risk Analizi, Şirket Satın Alma ve Birleşme, Türev Piyasalar, Teknik Analiz ve Finansal Okuryazarlık konularında ders vermektedir. Yeşilyurt’un bu konularda yayımlanmış makaleleri ve çalışmaları bulunmaktadır. Akademik hayatı boyunca bir çok STK’da girişimcilerin finansal becerilerinin arttırılması üzerine seminerler veren Yeşilyurt, danışmanlıklar yapmakta ve akademik projeler yürütmektedir. Evli ve 1 çocuk babası Yeşilyurt, iyi derecede İngilizce bilmektedir.</p>`
    },
    {
      img: require("@/assets/akin.png"),
      title: "Instructor",
      fullName: "Gokhan Akin",
      bio: `<p>Yirmi yıldır ağ teknolojileri ve güvenlik üzerine çalışan Gökhan Akın, kariyerine İTÜ Bilgi İşlem Daire Başkanlığında Ağ uzmanı olarak başlamıştır. İlerleyen süreçte ağ grubu takım lideri olarak görev almıştır. Bir dönem Tübitak/Ulakbim altında ki ULAK/CSIRT (Ulusal Akademik Ağ Siber Güvenlik Birimi'nin) beş üyesinden biri olarak görev yapmıştır. Kariyeri boyunca birçok kurum, ISP ve üniversiteye danışman olarak hizmet vermiştir. Ayrıca raylı ulaşım ve karayolu sistemlerine ağ projeleri hazırlayan Gökhan Akın, bunların yanında ağ uzmanlığı konusunda en prestijli sertifikalardan biri sayılan CCIE sertifikasına sahiptir. Ağ yönetimi ve siber güvenlik üzerine onlarca konferansa konuşmacı olarak katılmasının yanında on beş yılı aşkın bir süredir Cisco Networking Academy'de eğitmen eğitmeni olarak ağ teknolojileri ve güvenlik üzerine dersler vermekte ve yeni eğitmenler yetiştirmektedir. Akademik kariyerine Bilgisayar mühendisliği dalında doktora yaparak devam eden Gökhan Akın, halen özel bir üniversitede öğretim görevlisi olarak eğitim vermektedir. Kendisi şu an Ağ Yöneticileri Derneği’nin kurucu başkanlığını yapmaktadır.</p>`
    },
    {
      img: require("@/assets/aydan.png"),
      title: "Instructor",
      fullName: "Aydan Nergiz Akin",
      bio: `<p>Marmara Üniversite’si Halkla İlişkiler ve Tanıtım bölümü mezunudur. Kendisi uzun yıllar Migros AŞ’de mağaza yönetimi üzerine çalışmıştır. Halen Teknokare firmasında halkla ilişkiler uzmanı ve KVKK süreçleri yönetimi üzerine çalışmaktadır. Kendisi aynı zamanda üniversite etkinliklerinde öğrencilere kişisel gelişim süreçleri üzerine sunumlar yapmaktadır. Ayrıca Ağ Yöneticileri Derneği’nin Denetleme Kurulu Başkanı’dır.</p>`
    },
    {
      img: require("@/assets/merve.png"),
      title: "Project Assistant",
      fullName: "Merve Akar",
      bio: `<p>1999 yılında İstanbul’da doğdu. Lisans eğitimini 2017 – 2021 yılları arasında İstanbul Üniversitesi İletişim Fakültesi Halkla İlişkiler ve Tanıtım bölümünde tamamladı. Ayrıca Atatürk Üniversitesi Açıköğretim Fakültesi Fotoğrafçılık ve Kameramanlık önlisans bölümünü bitirdi. 2016 yılında Türk Hava Yolları Kurumsal Satış ve Pazarlama (Yurtiçi) departmanında lise stajını yaptı. Şu anda Medya Okuryazarlığı Derneği’nin Sosyal Medya Komisyonunda gönüllü olarak yer almaktadır ve Diji Vatandaş eğitim programında proje asistanıdır.</p>`
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showBio(member: any) {
    this.selectedMember = member;
    this.dialog = true;
  }
}
