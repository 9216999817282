
















import { Component, Vue } from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Banner from "./Banner.vue";
import About from "./About.vue";
import Faq from "./Faq.vue";
import Team from "./Team.vue";

@Component({
  components: {
    Breadcrumbs,
    Banner,
    About,
    Faq,
    Team
  },
  metaInfo: {
    title: "About Us"
  }
})
export default class AboutUsView extends Vue {
  breadcrumbs = [
    {
      text: "Home",
      exact: true,
      to: { name: "home-en" }
    },
    {
      text: "About Us"
    }
  ];
}
